import { dispatch } from "..";
import { actionTypes } from "./actions";
import { actionTypes as UserActionTypes } from "./../User/actions";
import { setCookie, getCookie } from "~/old-app/utils/helpers/cookieManager";
import { CookieEnum } from "~/old-app/constants";
const initState = {
  idToken: null,
  register: {
    isLoading: false,
    isSuccess: false,
    successMessage: null,
    isFailed: false,
    error: null,
  },
  verify: {
    isLoading: false,
    isSuccess: false,
    successMessage: null,
    isFailed: false,
    error: null,
  },

  login: {
    isLoading: false,
    isSuccess: false,
    successMessage: null,
    isFailed: false,
    error: null,
  },
  verifyOtp: {
    isLoading: false,
    isSuccess: false,
    successMessage: null,
    isFailed: false,
    error: null,
  },
  resetPassword: {
    isLoading: false,
    isSuccess: false,
    successMessage: null,
    isFailed: false,
    error: null,
  },
};
// register
const registerRequest = (state, payload) => ({
  ...state,
  register: { isLoading: true },
});
const registerRequestSuccess = (state, payload) => {
  setCookie(CookieEnum.Token, payload.token, { expires: 7 });
  localStorage.removeItem("@matjr__userCode");
  localStorage.removeItem("@matjr__userMobile");
  localStorage.removeItem("@matjr__userExists");

  window.location.href = "/account/my-address/add-address";

  return {
    ...state,
    register: {
      isLoading: false,
      isSuccess: true,
      successMessage: payload?.message,
      isFailed: false,
      error: null,
    },
  };
};
const registerRequestFailed = (state, payload) => ({
  ...state,
  register: {
    isLoading: false,
    isSuccess: false,
    successMessage: null,
    isFailed: true,
    error: payload,
  },
});
// verify
const verifyEmailRequest = (state, payload) => ({
  ...state,
  verify: { isLoading: true },
});
const verifyEmailRequestSuccess = (state, payload) => ({
  ...state,
  verify: {
    isLoading: false,
    isSuccess: true,
    successMessage: payload?.message,
    isFailed: false,
    error: null,
  },
});
const verifyEmailRequestFailed = (state, payload) => ({
  ...state,
  verify: {
    isLoading: false,
    isSuccess: false,
    successMessage: null,
    isFailed: true,
    error: payload,
  },
});
// login
const loginRequest = (state, payload) => {
  localStorage.setItem("@matjr__loginStatus", "loginRequest");
  return {
    ...state,
    login: { isLoading: true },
  };
};
const loginRequestSucces = (state, payload) => {
  localStorage.removeItem("@matjr__loginStatus");
  return {
    ...state,
    login: {
      isLoading: false,
      isSuccess: true,
      successMessage: payload?.message,
      isFailed: false,
      error: null,
      open: false,
    },
  };
};
const loginRequestFailed = (state, payload) => {
  localStorage.removeItem("@matjr__loginStatus");
  return {
    ...state,
    login: {
      isLoading: false,
      isSuccess: false,
      successMessage: null,
      isFailed: true,
      error: payload,
      open: false,
    },
  };
};

// verify otp
const verifyOtpRequest = (state, payload) => ({
  ...state,
  verifyOtp: { isLoading: true },
});
const verifyOtpRequestSuccess = (state, payload) => {
  localStorage.setItem("@matjr__userExists", payload.user_exists);

  if (payload.user_exists) {
    // client.defaults.headers.common.Authorization = `Bearer ${payload.token}`;
    setCookie(CookieEnum.Token, payload.token, { expires: 7 });

    const useDefaultLocation = getCookie(CookieEnum.DefaultLocation);
    // localStorage.removeItem("@matjr__statusModal");
    if (useDefaultLocation) {
      setTimeout(() => {
        window.location.href = "/";
      }, 500);
    } else {
      window.location.href = "/account/my-address/add-address";
    }

    localStorage.removeItem("@matjr__userCode");
    localStorage.removeItem("@matjr__userMobile");
    localStorage.removeItem("@matjr__userExists");
  } else {
    localStorage.setItem("@matjr__statusModal", "register");
  }

  return {
    ...state,
    verifyOtp: {
      isLoading: false,
      isSuccess: true,
      successMessage: payload?.message,
      isFailed: false,
      error: null,
    },
  };
};

const verifyOtpRequestFailed = (state, payload) => ({
  ...state,
  verifyOtp: {
    isLoading: false,
    isSuccess: false,
    successMessage: null,
    isFailed: true,
    error: payload,
  },
});
// forget pass
const forgetPasswordRequest = (state, payload) => ({
  ...state,
  forgetPassword: { isLoading: true },
});
const forgetPasswordSucces = (state, payload) => ({
  ...state,
  forgetPassword: {
    isLoading: false,
    isSuccess: true,
    successMessage: payload?.message,
    isFailed: false,
    error: null,
  },
});
const forgetPasswordFailed = (state, payload) => ({
  ...state,
  forgetPassword: {
    isLoading: false,
    isSuccess: false,
    successMessage: null,
    isFailed: true,
    error: payload,
  },
});
// reset pass
const resetPasswordRequest = (state, payload) => ({
  ...state,
  resetPassword: { isLoading: true },
});
const resetPasswordSucces = (state, payload) => ({
  ...state,
  resetPassword: {
    isLoading: false,
    isSuccess: true,
    successMessage: payload?.message,
    isFailed: false,
    error: null,
  },
});
const resetPasswordFailed = (state, payload) => ({
  ...state,
  resetPassword: {
    isLoading: false,
    isSuccess: false,
    successMessage: null,
    isFailed: true,
    error: payload,
  },
});
const loginOpen = (state, payload) => ({
  ...state,
  login: {
    isLoading: false,
    isSuccess: false,
    successMessage: null,
    isFailed: false,
    error: null,
    open: payload,
  },
});

const loginInfluencerRequest = (state, payload) => ({
  ...state,
  login: { isLoading: true },
});

const loginInfluencerSuccess = (state, payload) => {
  // client.defaults.headers.common.Authorization = `Bearer ${payload.token}`;
  setCookie(CookieEnum.Token, payload.token, { expires: 7 });

  const useDefaultLocation = getCookie(CookieEnum.DefaultLocation);

  // localStorage.removeItem("@matjr__statusModal");
  if (useDefaultLocation) {
    setTimeout(() => {
      window.location.href = "/";
    }, 500);
  } else {
    window.location.href = "/account/my-address";
  }

  localStorage.removeItem("@matjr__userCode");
  localStorage.removeItem("@matjr__userMobile");
  localStorage.removeItem("@matjr__userExists");

  return {
    ...state,
    verifyOtp: {
      isLoading: false,
      isSuccess: true,
      successMessage: payload?.message,
      isFailed: false,
      error: null,
    },
  };
};

const loginInfluencerFailed = (state, payload) => ({
  ...state,
  verifyOtp: {
    isLoading: false,
    isSuccess: false,
    successMessage: null,
    isFailed: true,
    error: payload,
  },
});

export default function authReducer(state = initState, { type, payload }) {
  switch (type) {
    // register
    case actionTypes.REGISTER_REQUEST:
      return registerRequest(state, payload);
    case actionTypes.REGISTER_REQUEST_SUCCESS:
      return registerRequestSuccess(state, payload);
    case actionTypes.REGISTER_REQUEST_FAILED:
      return registerRequestFailed(state, payload);

    // verify
    case actionTypes.VERIFY_EMAIL_REQUEST:
      return verifyEmailRequest(state, payload);
    case actionTypes.VERIFY_EMAIL_REQUEST_SUCCESS:
      return verifyEmailRequestSuccess(state, payload);
    case actionTypes.VERIFY_EMAIL_REQUEST_FAILED:
      return verifyEmailRequestFailed(state, payload);
    // login
    case actionTypes.LOGIN_REQUEST:
      return loginRequest(state, payload);
    case actionTypes.LOGIN_REQUEST_SUCCESS:
      return loginRequestSucces(state, payload);
    case actionTypes.LOGIN_REQUEST_FAILED:
      return loginRequestFailed(state, payload);

    case actionTypes.LOGIN_OPEN:
      return loginOpen(state, payload);
    // verifyOtp
    case actionTypes.VERIFY_OTP_REQUEST:
      return verifyOtpRequest(state, payload);
    case actionTypes.VERIFY_OTP_REQUEST_SUCCESS:
      return verifyOtpRequestSuccess(state, payload);
    case actionTypes.VERIFY_OTP_REQUEST_FAILED:
      return verifyOtpRequestFailed(state, payload);
    // forget
    case actionTypes.FORGET_PASSWORD_REQUEST:
      return forgetPasswordRequest(state, payload);
    case actionTypes.FORGET_PASSWORD_SUCCESS:
      return forgetPasswordSucces(state, payload);
    case actionTypes.FORGET_PASSWORD_FAILED:
      return forgetPasswordFailed(state, payload);
    // reset
    case actionTypes.RESET_PASSWORD_REQUEST:
      return resetPasswordRequest(state, payload);
    case actionTypes.RESET_PASSWORD_SUCCESS:
      return resetPasswordSucces(state, payload);
    case actionTypes.RESET_PASSWORD_FAILED:
      return resetPasswordFailed(state, payload);
    // case actionTypes.LOGIN_REQUEST:
    //   return loginRequest(state, payload)
    // case actionTypes.LOGIN_REQUEST_SUCCESS:
    //   return {
    //     idToken: payload.token,
    //   }
    case actionTypes.LOGOUT_SUCCESS:
      // window.location.reload()

      if (payload.shouldRedirect) {
        // localStorage.removeItem('@matjr_user_panel')
        // localStorage.removeItem('organization-handle')
        // window.location.replace('/landing')
        window.location.reload();
      }
      return initState;
    case actionTypes.FLUSH_AUTH_REDUCER:
      return initState;

    case actionTypes.LOGIN_INFLUENCER_REQUEST: {
      return loginInfluencerRequest(state, payload);
    }
    case actionTypes.LOGIN_INFLUENCER_SUCCESS: {
      return loginInfluencerSuccess(state, payload);
    }

    case actionTypes.LOGIN_INFLUENCER_FAILED: {
      return loginInfluencerFailed(state, payload);
    }

    default:
      return state;
  }
}
