import renameFunc from "../../utils/helpers/renameFunc";
import lodash from "lodash";
const { camelCase: _camelCase, get: _get } = lodash;

export const actionTypes = {
  USERPROFILE_REQUEST: "USERPROFILE_REQUEST 🚀 💬",
  USERPROFILE_REQUEST_SUCCESS: "USERPROFILE_REQUEST_SUCCESS 🚀 ✅",
  USERPROFILE_REQUEST_FAILED: "USERPROFILE_REQUEST_FAILED 🚀 ❌",
  UPDATE_PROFILE_REDUCER_DATA: "UPDATE_PROFILE_REDUCER_DATA 🚀 ✅",

  INFLUENCER_PROFILE_REQUEST: "INFLUENCER_PROFILE_REQUEST 🚀 💬",
  INFLUENCER_PROFILE_REQUEST_SUCCESS:
    "INFLUENCER_PROFILE_REQUEST_SUCCESS 🚀 ✅",
  INFLUENCER_PROFILE_REQUEST_FAILED: "INFLUENCER_PROFILE_REQUEST_FAILED 🚀 ❌",
};
// helper function automatically generate ACTION CREATORS!
export const actions = Object.keys(actionTypes).reduce((accum, id) => {
  const creatorName = _camelCase(id);

  const creatorFunction = function _(payload) {
    return {
      type: _get(actionTypes, id),
      payload,
    };
  };

  // eslint-disable-next-line no-param-reassign
  accum[creatorName] = renameFunc(creatorFunction, creatorName);
  return accum;
}, {});
