import language from "../../config/language.config";

// import englishLang from 'assets/images/flag/uk.svg';
// import arabicLang from 'assets/images/flag/ar.svg';

const config = {
  defaultLanguage: language,
  options: [
    {
      languageId: "english",
      locale: "en",
      text: "English",
      // icon: englishLang,
    },
    {
      languageId: "arabic",
      locale: "ar",
      text: "Arabic",
      // icon: arabicLang,
    },
  ],
};

export function getCurrentLanguage(lang) {
  let selecetedLanguage = config.options[0];
  config.options.forEach((language) => {
    if (language.languageId === lang) {
      selecetedLanguage = language;
    }
  });
  return selecetedLanguage;
}
export default config;
