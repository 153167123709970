import type { Middleware, MiddlewareAPI } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import HandleAxiosError from "../utils/helpers/HandleAxiosError";
import { redirect } from "react-router-dom";
import { dispatch } from ".";
import { AuthActions } from "./auth-slice";

type ErrorPayload = {
  status: number;
  data: {
    errors: string[];
  };
};

type ActionWithPayload = {
  payload: ErrorPayload;
  type: string;
};

export const ErrorLoggerMiddleware: Middleware =
  (api: MiddlewareAPI) => (next) => (_action: any) => {
    const action = _action as ActionWithPayload;
    const status = action?.payload?.status;

    // make log for every api called, just for apis
    if (
      action.type.includes("fulfilled") &&
      (window as any)?.ENV?.APP_MODE === "development"
    ) {
      // console.log(
      //   `%cAPI CALL: ${action.type}`,
      //   "color: green; font-weight: bold",
      //   `%c URL: ${_action?.meta?.baseQueryMeta?.request?.url}`,
      //   "color: red; font-weight: bold",
      //   action.payload
      // );
    }

    if (
      action.payload?.data?.errors ||
      [400, 410, 422, 403, 404, 500, 502, 503, 504, 401].includes(status)
    ) {
      const errorMessage = HandleAxiosError({ response: action.payload });

      toast.error(errorMessage);

      if (status === 401) {
        dispatch(AuthActions.logout());
        const path =
          typeof window !== "undefined" ? window.location.pathname : "";
        redirect(`/signin?redirectUrl=${path}`);
      }
    }

    return next(action);
  };
