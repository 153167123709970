import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { shallowEqual, useSelector } from "react-redux";
import { RootState } from ".";
import { CartDetails, OfflineCartItem } from "../api/cart";
import { PaymentMethod } from "../api/system-setting";
import { CookieStorage } from "redux-persist-cookie-storage";
import Cookies from "js-cookie";
import { persistReducer } from "redux-persist";

const NAME = "cart";
type ICartState = {
  cart?: CartDetails;
  isLoading?: boolean;
  paymentMethods?: PaymentMethod[];
  offlineCartItems?: OfflineCartItem[];
  selectedPaymentMethod?: PaymentMethod;
};
const initialState: ICartState = {
  isLoading: true,
  paymentMethods: [],
  offlineCartItems: [],
};
export const CartSlice = createSlice({
  name: NAME,
  initialState: initialState,
  reducers: {
    setCart: (state, action: PayloadAction<ICartState>) => {
      return {
        ...state,
        cart: action.payload.cart,
        isLoading: false,
        paymentMethods: action.payload.paymentMethods,
      };
    },

    setLoading: (state, action: PayloadAction<boolean>) => {
      return { ...state, isLoading: action.payload };
    },

    addToCart: (
      state,
      action: PayloadAction<
        OfflineCartItem & {
          increaseQty?: boolean;
        }
      >
      // action: PayloadAction<Required<Pick<ICartState, "offlineCartItems">>>
    ) => {
      const existingItemIndex =
        state.offlineCartItems?.findIndex(
          (item) => item.id === action.payload.id
        ) ?? -1;

      let offlineCartItems;
      if (existingItemIndex >= 0) {
        offlineCartItems = state.offlineCartItems?.map((item, index) =>
          index === existingItemIndex
            ? {
                ...item,
                qty: action.payload?.increaseQty ? item.qty + 1 : item.qty,
              }
            : item
        );
      } else {
        offlineCartItems = [...state.offlineCartItems!, action.payload];
      }

      return {
        ...state,
        offlineCartItems,
      };
    },
    removeFromCart: (state, action: PayloadAction<OfflineCartItem>) => {
      const offlineCarts = state.offlineCartItems ?? [];
      const offlineCartItems = offlineCarts.filter(
        (item) => item.id !== action.payload?.id
      );

      // dispatch(CartApi.util.invalidateTags(["offline-cart"]));
      // state.cart.push(action.payload);

      return {
        ...state,
        offlineCartItems,
      };
    },

    emptyCart: (state) => {
      return {
        ...state,
        offlineCartItems: [],
      };
    },

    setSelectedPaymentMethod: (state, action: PayloadAction<PaymentMethod>) => {
      return {
        ...state,
        selectedPaymentMethod: action.payload,
      };
    },
  },
});

export const CartActions = CartSlice.actions;
export const CartSelector = (state: RootState) => state.cart;

// export const CartReducer = CartSlice.reducer;

export const CartReducer = persistReducer(
  {
    storage: new CookieStorage(Cookies /*, options */),
    // storage: localStorage,
    key: NAME,
    debug: false,
    whitelist: ["offlineCartItems"],
  },
  CartSlice.reducer
);

export const useCartSlice = () => useSelector(CartSelector, shallowEqual);
