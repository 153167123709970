import renameFunc from "../../utils/helpers/renameFunc";
import lodash from "lodash";
const { camelCase: _camelCase, get: _get } = lodash;

export const actionTypes = {
  SELECTED_SERVICE_REQUEST: "SELECTED_SERVICE_REQUEST 🚀 💬",
  SELECTED_SERVICE_REQUEST_SUCCESS: "SELECTED_SERVICE_REQUEST_SUCCESS 🚀 ✅",
  SELECTED_SERVICE_REQUEST_FAILED: "SELECTED_SERVICE_REQUEST_FAILED 🚀 ❌",
  TOGGLE_ITEM_FAV: "TOGGLE_ITEM_FAV 🚀  ✅",
  RESET_BRANCHES: "RESET_BRANCHES",
  RESET_PRODUCTS: "RESET_PRODUCTS",
};
// helper function automatically generate ACTION CREATORS!
export const actions = Object.keys(actionTypes).reduce((accum, id) => {
  const creatorName = _camelCase(id);

  const creatorFunction = function _(payload) {
    return {
      type: _get(actionTypes, id),
      payload,
    };
  };

  // eslint-disable-next-line no-param-reassign
  accum[creatorName] = renameFunc(creatorFunction, creatorName);
  return accum;
}, {});
