import renameFunc from "../../utils/helpers/renameFunc";
import lodash from "lodash";
const { camelCase: _camelCase, get: _get } = lodash;

export const actionTypes = {
  PRODUCTS_REQUEST: "PRODUCTS_REQUEST 🚀 💬",
  PRODUCTS_REQUEST_SUCCESS: "PRODUCTS_REQUEST_SUCCESS 🚀 ✅",
  PRODUCTS_REQUEST_FAILED: "PRODUCTS_REQUEST_FAILED 🚀 ❌",

  TOGGLE_ITEM_FAV: "TOGGLE_ITEM_FAV 🚀  ✅",
};
// helper function automatically generate ACTION CREATORS!
export const actions = Object.keys(actionTypes).reduce((accum, id) => {
  const creatorName = _camelCase(id);

  const creatorFunction = function _(payload) {
    return {
      type: _get(actionTypes, id),
      payload,
    };
  };

  // eslint-disable-next-line no-param-reassign
  accum[creatorName] = renameFunc(creatorFunction, creatorName);
  return accum;
}, {});
