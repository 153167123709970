import { actionTypes } from "./actions";

const initState = {
  isLoading: false,
  isSuccess: false,
  successMessage: null,
  isFailed: false,
  error: null,
};

const verifyOtpRequestSuccess = (state, payload) => ({
  ...state,
  ...payload,
});
const registerRequestSuccess = (state, payload) => ({
  ...state,
  ...payload,
});
const profileRequest = (state, payload) => {
  return {
    ...state,
    isLoading: true,
  };
};
const profileRequestSuccess = (state, payload) => ({
  ...state,
  user: {
    ...payload.user,
  },
  isLoading: false,
  isSuccess: true,
  successMessage: payload.message,
  isFailed: false,
  error: null,
});
const profileRequestFailed = (state, payload) => ({
  ...state,
  isLoading: false,
  isSuccess: false,
  successMessage: null,
  isFailed: true,
  error: payload,
});

const updateUserReducerData = (state, payload) => ({
  ...state,
  user: {
    ...state.user,
    ...payload,
  },
});

const loginInfluencerSuccess = (state, payload) => ({
  ...state,
  ...payload,
  user: {
    ...state.user,
    ...payload.user,
    role: "influencer",
  },
});

export default function authReducer(state = initState, { type, payload }) {
  switch (type) {
    case actionTypes.VERIFY_OTP_REQUEST_SUCCESS:
      return verifyOtpRequestSuccess(state, payload);
    case actionTypes.LOGIN_INFLUENCER_SUCCESS:
      return loginInfluencerSuccess(state, payload);
    case actionTypes.REGISTER_REQUEST_SUCCESS:
      return registerRequestSuccess(state, payload);
    case actionTypes.PROFILE_REQUEST:
      return profileRequest(state, payload);
    case actionTypes.PROFILE_REQUEST_SUCCESS:
      return profileRequestSuccess(state, payload);
    case actionTypes.PROFILE_REQUEST_FAILED:
      return profileRequestFailed(state, payload);
    case actionTypes.UPDATE_USER_REDUCER_DATA:
      return updateUserReducerData(state, payload);
    default:
      return state;
  }
}
