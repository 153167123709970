import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { shallowEqual, useSelector } from "react-redux";
import { RootState } from ".";

const NAME = "modal";
type IModalState = {
  isSignInOpen?: boolean;
  isSignUpOpen?: boolean;
  isForgetPasswordOpen?: boolean;
  isVerifyOtpOpen?: boolean;
  isInfluencerModalOpen?: boolean;
};
const initialState: IModalState = {
  isSignInOpen: false,
  isSignUpOpen: false,
  isForgetPasswordOpen: false,
  isVerifyOtpOpen: false,
  isInfluencerModalOpen: false,
};
export const ModalSlice = createSlice({
  name: NAME,
  initialState: initialState,
  reducers: {
    setAuthModal: (
      state,
      {
        payload,
      }: PayloadAction<
        Pick<
          IModalState,
          | "isSignInOpen"
          | "isSignUpOpen"
          | "isForgetPasswordOpen"
          | "isVerifyOtpOpen"
          | "isInfluencerModalOpen"
        >
      >
    ) => {
      return { ...state, ...payload };
    },
  },
});

export const ModalActions = ModalSlice.actions;
export const modalSelector = (state: RootState) => state.modal;

export const ModalReducer = ModalSlice.reducer;

export const useModalSlice = () => useSelector(modalSelector, shallowEqual);
