import renameFunc from "../../utils/helpers/renameFunc";
import lodash from "lodash";
const { camelCase: _camelCase, get: _get } = lodash;

export const actionTypes = {
  CREATE_ORDER_REQUEST: "CREATE_ORDER_REQUEST 🚀 💬",
  CREATE_ORDER_SUCCESS: "CREATE_ORDER_SUCCESS 🚀 ✅",
  CREATE_ORDER_FAILED: "CREATE_ORDER_FAILED 🚀 ❌",

  UPDATE_ORDER_REQUEST: "UPDATE_ORDER_REQUEST 🚀 💬",
  UPDATE_ORDER_SUCCESS: "UPDATE_ORDER_SUCCESS 🚀 ✅",
  UPDATE_ORDER_FAILED: "UPDATE_ORDER_FAILED 🚀 ❌",

  DELETE_ORDER_REQUEST: "DELETE_ORDER_REQUEST 🚀 💬",
  DELETE_ORDER_SUCCESS: "DELETE_ORDER_SUCCESS 🚀 ✅",
  DELETE_ORDER_FAILED: "DELETE_ORDER_FAILED 🚀 ❌",

  ORDERS_REQUEST: "ORDERS_REQUEST 🚀 💬",
  ORDERS_REQUEST_SUCCESS: "ORDERS_REQUEST_SUCCESS 🚀 ✅",
  ORDERS_REQUEST_FAILED: "ORDERS_REQUEST_FAILED 🚀 ❌",

  ADD_COUPON_REQUEST: "ADD_COUPON_REQUEST 🚀 💬",
  ADD_COUPON_SUCCESS: "ADD_COUPON_SUCCESS 🚀 ✅",
  ADD_COUPON_FAILED: "ADD_COUPON_FAILED 🚀 ❌",

  TOGGLE_ITEM_FAV: "TOGGLE_ITEM_FAV 🚀 ❌",
  HANDLE_COUPON_REQUEST: "HANDLE_COUPON_REQUEST 🚀 ❌",

  CLEAR_ERROR: "CLEAR_ERROR 🚀 🧹",
};
// helper function automatically generate ACTION CREATORS!
export const actions = Object.keys(actionTypes).reduce((accum, id) => {
  const creatorName = _camelCase(id);

  const creatorFunction = function _(payload) {
    return {
      type: _get(actionTypes, id),
      payload,
    };
  };

  // eslint-disable-next-line no-param-reassign
  accum[creatorName] = renameFunc(creatorFunction, creatorName);
  return accum;
}, {});
