import { actionTypes } from "./actions";
import branchesReducer from "../branches/reducer";
import productsReducer from "../products/reducer";
const initState = {
  selectedService: {
    isLoading: true,
    isSuccess: false,
    successMessage: null,
    isFailed: false,
    error: null,
    serviceDetails: null,
  },
};

const selectedServiceRequest = (state, payload) => ({
  ...state,
  selectedService: { isLoading: true, serviceDetails: null },
});
const selectedServiceRequestSuccess = (state, payload) => ({
  ...state,
  selectedService: {
    isLoading: false,
    isSuccess: true,
    // successMessage: payload?.message,
    successMessage: null,
    isFailed: false,
    error: null,
    serviceDetails: payload,
  },
});
const selectedServiceRequestFailed = (state, payload) => ({
  ...state,
  selectedService: {
    isLoading: false,
    isSuccess: false,
    successMessage: null,
    isFailed: true,
    error: payload,
  },
});
const toggleItemFav = (state, payload) => {
  const { id, type } = payload;
  // Check if selectedService and blocks are defined
  if (
    !state.selectedService.serviceDetails ||
    !state.selectedService.serviceDetails.blocks
  ) {
    console.error("selectedService or blocks are undefined");
    return state; // Return the original state if selectedService or blocks are undefined
  }

  // Clone the state to avoid direct mutation
  const newState = {
    ...state,
    selectedService: {
      ...state.selectedService,
      serviceDetails: {
        blocks: state.selectedService.serviceDetails.blocks.map((block) => {
          if (block.type === type) {
            // Check if items are defined
            if (!block.items) {
              console.warn("Block items are undefined");
              return block; // Return the original block if items are undefined
            }

            // Iterate over the items in the block
            const newItems = block.items.map((item) => {
              if (item.id === id) {
                // Toggle the is_favorite key
                return { ...item, is_favorite: !item.is_favorite };
              }
              return item;
            });

            return {
              ...block,
              items: newItems,
            };
          }
          return block;
        }),
      },
    },
  };


  return newState;
};

const selectedServiceReducer = (state = initState, { type, payload }) => {
  switch (type) {
    case actionTypes.SELECTED_SERVICE_REQUEST:
      return {
        ...selectedServiceRequest(state, payload),
        serviceBranches: branchesReducer(state.serviceBranches, {
          type: actionTypes.RESET_BRANCHES,
        }),
        serviceProducts: productsReducer(state.serviceProducts, {
          type: actionTypes.RESET_PRODUCTS,
        }),
      };
    case actionTypes.SELECTED_SERVICE_REQUEST_SUCCESS:
      return selectedServiceRequestSuccess(state, payload);
    case actionTypes.SELECTED_SERVICE_REQUEST_FAILED:
      return selectedServiceRequestFailed(state, payload);
    case actionTypes.TOGGLE_ITEM_FAV:
      return toggleItemFav(state, payload);
    default:
      return state;
  }
};

export default selectedServiceReducer;
