import renameFunc from "../../utils/helpers/renameFunc";
import lodash from "lodash";
const { camelCase: _camelCase, get: _get } = lodash;

export const actionTypes = {
  VERIFY_OTP_REQUEST_SUCCESS: "VERIFY_OTP_REQUEST_SUCCESS 🚀 ✅",
  REGISTER_REQUEST_SUCCESS: "REGISTER_REQUEST_SUCCESS 🚀 ✅",
  PROFILE_REQUEST: "PROFILE_REQUEST 🚀 💬",
  PROFILE_REQUEST_SUCCESS: "PROFILE_REQUEST_SUCCESS 🚀 ✅",
  PROFILE_REQUEST_FAILED: "PROFILE_REQUEST_FAILED 🚀 ❌",

  UPDATE_USER_REDUCER_DATA: "UPDATE_USER_REDUCER_DATA 🚀 ✅",

  LOGIN_INFLUENCER_REQUEST: "LOGIN_INFLUENCER_REQUEST 🚀 💬",
  LOGIN_INFLUENCER_SUCCESS: "LOGIN_INFLUENCER_SUCCESS 🚀 ✅",
  LOGIN_INFLUENCER_FAILED: "LOGIN_INFLUENCER_FAILED 🚀 ❌",
};
// helper function automatically generate ACTION CREATORS!
export const actions = Object.keys(actionTypes).reduce((accum, id) => {
  const creatorName = _camelCase(id);

  const creatorFunction = function _(payload) {
    return {
      type: _get(actionTypes, id),
      payload,
    };
  };

  // eslint-disable-next-line no-param-reassign
  accum[creatorName] = renameFunc(creatorFunction, creatorName);
  return accum;
}, {});
