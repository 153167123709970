import { applyMiddleware, createStore } from "redux";
import { createEpicMiddleware } from "redux-observable";
import { persistStore } from "redux-persist";
import { thunk as reduxThunk } from "redux-thunk";
import { Api } from "../api/api-store";
import { ErrorLoggerMiddleware } from "./error-middleware";
import rootEpics from "./root-epics";
import rootReducer from "./root-reducer";
// export { default as queryClient } from "./queryClient";

// const epicMiddleware = createEpicMiddleware();
const middlewares = [
  reduxThunk,
  // epicMiddleware,
  Api.middleware,
  ErrorLoggerMiddleware,
];

// const persistedReducer = persistReducer(persistConfig, );

const store = createStore(rootReducer, applyMiddleware(...middlewares)); // Apply middlewares correctly
// const persistedReducer = persistReducer(persistConfig, rootReducer);
// const store = createStore(persistedReducer, applyMiddleware(...middlewares)); // Apply middlewares correctly

const persistor = persistStore(store);
// epicMiddleware.run(rootEpics);

const getState = store.getState;
const dispatch = store.dispatch;

export type RootState = ReturnType<typeof store.getState>;

export { dispatch, getState, persistor, store };
