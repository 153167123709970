import lodash from "lodash";
const { isArray: _isArray } = lodash;

export default function HandleAxiosError(error) {
  try {
    if (!error) {
      return "An unknown error occurred."; // Fallback for null errors
    }

    if (error.response) {
      const { status, data } = error.response;

      if (status === 422) {
        if (data.errors) {
          const errorsArr = Object.values(data.errors);
          if (_isArray(errorsArr[0])) {
            return errorsArr[0][0] || "Validation error occurred.";
          } else {
            return errorsArr[0] || "Validation error occurred.";
          }
        }
        return data.message || "Validation error occurred.";
      }

      if (status === 400) {
        if (data.errors) {
          const errorContent = data.errors.error;
          if (_isArray(errorContent)) {
            return errorContent[0]; // Return the first element of the array
          } else {
            return errorContent;
          }
        }
        return data.message || "Bad request error occurred.";
      }

      return data.message || `An error occurred with status code ${status}.`;
    }

    // Handle cases where no response was received
    else if (error.request) {
      return "No response received from server.";
    }

    return `Error: ${error.message}`;
  } catch (err) {
    console.error("Error handling failed:", err);
    return "An error occurred while processing the error.";
  }
}
