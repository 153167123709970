import { CookieEnum } from "~/old-app/constants";
import {
  getCookie,
  getRequestCookie,
} from "~/old-app/utils/helpers/cookieManager";

export function getTokenFromCookies(request = null) {
  if (request) {
    return getRequestCookie(request, CookieEnum.Token);
  }
  return getCookie(CookieEnum.Token);
}
// export function getTokenFromCookies(request = null) {
//   if (typeof window === "undefined" && request) {
//     // Server-side, use the request object
//     return getRequestCookie(request, CookieEnum.Token);
//   } else {
//     // Client-side, use document.cookie
//     return getCookie(CookieEnum.Token);
//   }
// }
