import config, { getCurrentLanguage } from "./config";
import actions from "./actions";
import { getTokenFromCookies } from "~/old-app/utils/helpers/getToken";
import { setCookie, getCookie } from "~/old-app/utils/helpers/cookieManager";
import { CookieEnum } from "~/old-app/constants";
let langLocal;
try {
  langLocal = getCookie(CookieEnum.Language);
} catch (error) {
  console.error("Error accessing storage:", error);
  langLocal = null;
}

const initState = {
  isActivated: false,
  language: langLocal
    ? getCurrentLanguage(langLocal)
    : getCurrentLanguage(config.defaultLanguage || "english"),
};

export default function (state = initState, action) {
  switch (action.type) {
    case actions.ACTIVATE_LANG_MODAL:
      return {
        ...state,
        isActivated: !state.isActivated,
      };
    case actions.CHANGE_LANGUAGE:
      try {
        const token = getTokenFromCookies();
        if (!token) {
          setCookie(CookieEnum.Language, action.language.languageId, {
            expires: 7,
          });
        }
      } catch (error) {
        console.error("Error accessing storage:", error);
      }
      // window.location.reload();
      return {
        ...state,
        language: action.language,
      };
    default:
      return state;
  }
}
